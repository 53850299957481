import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import ThriftProud from "../../pages/ThriftProud";
import { RootState } from "../../reducers";
import client from "../../Client";
import Cookies from "universal-cookie";
import i18next from "i18next";
import { getLocale } from "../../api/cms/api";
import { getDomain } from "../../api/cms/api";
import { useLocation, useHistory } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import { BLOCKS } from "@contentful/rich-text-types";
import { INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { addLangKeyPath } from "../../api/cms/api";
import {
  getGlobalData,
  getHrefLang,
  getDefaultLanguage,
  getLocationApiKey,
} from "../../api/cms/api";
import Helmet from "react-helmet";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";

import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import SearchInput from "../SearchInput";
import FindStore from "../SearchInput/FindStore";
import { css } from "@emotion/react";
/** @jsx jsx */
import { jsx } from "@emotion/react";

const cookies = new Cookies();
let defaultLangLabel = "English";

const Header: React.FC<any> = (props) => {
  const locationApiKey = getLocationApiKey();
  const defaultLanguage = getDefaultLanguage();
  const currLocale = getLocale();
  const location = useLocation();
  const search = location.search;
  let history = useHistory();
  const currDomain = getDomain();

  let hostname = window.location.hostname;
  const charlist = "/";
  hostname = hostname.replace(new RegExp("[" + charlist + "]+$"), "");

  const langCookieKey = cookies.get("Language").toUpperCase();

  const globalData = getGlobalData();
  const storeUrl = globalData.storeUrl;
  const storeSite = globalData.storeSite;
  const countryCode = globalData.countryCode;
  const langToggleOpts = globalData.langToggleOpts;

  const is2ndAve = storeSite === "2a";

  const nondisclosureMsgCookie = cookies.get("nondisclosure_message");
  let expandOpts: any = {};
  let minimizeOpts: any = {};

  if (nondisclosureMsgCookie == "minimize") {
    expandOpts["display"] = "none";
  } else {
    minimizeOpts["display"] = "none";
  }

  const [topHeaderBar, setTopHeaderBar] = useState("");
  const [siteLogo, setSiteLogo] = useState("");
  const [siteLogoAltText, setSiteLogoAltText] = useState("");
  const [siteMainMenu, setSiteMainMenu] = useState([]);
  const [siteMainMenuLength, setSiteMainMenuLength] = useState(0);
  const [topMenu, setTopMenu] = useState([]);
  const [topMenuLength, setTopMenuLength] = useState(0);
  const [thriftProudNavigation, setThriftProudNavigation] = useState([]);
  const [thriftProudNavigationLength, setThriftProudNavigationLength] =
    useState(0);
  const [searchText, setSearchText] = useState("");
  const [storeText, setStoreText] = useState("");
  const [mobileStoreText, setMobileStoreText] = useState("");
  const [currLang, setCurrLang] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [path, setPath] = useState("");
  const [storeLocation, setStoreLocation] = useState([]);
  const [value, setValue] = useState("");
  const [placeholder, setPlaceholder] = useState("Search");
  const [searchPlaceholderText, setSearchPlaceholderText] = useState("");
  const [dynamicClassName, setDynamicClassName] = useState("");
  const [expandedText, setExpandedText] = useState("");
  const [minimizedText, setMinimizedText] = useState("");
  const [gtmScripts, setGtmScripts] = useState([]);
  const [dynamicToggleClass, setDynamicToggleClass] = useState("");
  const [options, setOptions] = useState<any>([]);
  const [gtagId, setGtagId] = useState("");
  const [gtagAdditionalCode, setGtagAdditionalCode] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [temporaryModalDescription, setTemporaryModalDescription] =
    useState("");
  const [temporaryModalBg, setTemporaryModalBg] = useState("");
  const [temporaryModalIcon, setTemporaryModalIcon] = useState("");
  const [showCountryPopup, setShowCountryPopup] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [countryLangToggle, setCountryLangToggle] = useState(false);
  const [
    automaticallyRedirectCaUsersFromVvusToVvca,
    setAutomaticallyRedirectCaUsersFromVvusToVvca,
  ] = useState(false);
  const [allLinksArray, setAllLinksArray] = useState([]);
  const [staticLinksArray, setStaticLinksArray] = useState([]);
  const [embededHtml, setEmbededHtml] = useState("");
  const [embededHtmlExpanded, setEmbededHtmlExpanded] = useState("");
  const [searchMagnifyingGlassColor, setSearchMagnifyingGlassColor] =
    useState("#E1251B");
  const [searchInlineTextColor, setSearchInlineTextColor] = useState("#000000");
  const [topPromoBarColor, setTopPromoBarColor] = useState("#004E7D");
  const [siteMainMenuColor, setSiteMainMenuColor] = useState("#E1251B");
  const [navbarLinkColor, setNavbarLinkColor] = useState("#E1251B");
  const [navbarLinkSeparatorColor, setNavbarLinkSeparatorColor] =
    useState("#E1251B");
  const [desktopNavbarLinkColor, setDesktopNavbarLinkColor] = useState("white");
  const [hideSearchPills, setHideSearchPills] = useState(false);

  let date = new Date(); // Now
  date.setDate(date.getDate() + 30);

  const getLanguage = () => {
    let pathArray = window.location.pathname.split("/");

    if (pathArray[1] !== undefined && pathArray[1] == "en") {
      defaultLangLabel = "English";
      return "en";
    } else if (pathArray[1] !== undefined && pathArray[1] == "fr") {
      defaultLangLabel = "French";
      return "fr";
    } else if (pathArray[1] !== undefined && pathArray[1] == "es") {
      defaultLangLabel = "Spanish";
      return "es";
    } else if (
      cookies.get("Language") !== undefined &&
      cookies.get("Language") !== ""
    ) {
      return cookies.get("Language");
    }
    return "en";
  };

  useEffect(() => {
    const currLocale = getLocale();

    // Get gray top bar disclosure embeded html
    let headerEntryId = props.headerEntryId;
    let disclosure_id = "";

    if (headerEntryId != "" && headerEntryId != undefined) {
      client
        .getEntry(headerEntryId, { locale: currLocale })
        .then((entry: any) => {
          let allFieldsData = entry.fields;
          if (
            allFieldsData.topHeaderBarBackgroundColor != undefined &&
            allFieldsData.topHeaderBarBackgroundColor != ""
          ) {
            setTopPromoBarColor(allFieldsData.topHeaderBarBackgroundColor);
          }
          if (
            allFieldsData.siteMainMenuBackgroundColor !== undefined &&
            allFieldsData.siteMainMenuBackgroundColor !== ""
          ) {
            setSiteMainMenuColor(allFieldsData.siteMainMenuBackgroundColor);
          }
          if (
            allFieldsData.nondisclosureMessage !== undefined &&
            allFieldsData.nondisclosureMessage !== "" &&
            "target" in
              allFieldsData.nondisclosureMessage[0].fields.minimizedText
                .content[1].data
          ) {
            disclosure_id =
              allFieldsData.nondisclosureMessage[0].fields.minimizedText
                .content[1].data.target.sys.id;
          }
          return disclosure_id;
        })
        .then((entry: any) => {
          if (disclosure_id !== "") {
            client
              .getEntry(disclosure_id, { locale: currLocale })
              .then((entry: any) => {
                //console.log("TEST:", entry)
                return entry.fields.html;
              })
              .then((html: any) => {
                setEmbededHtml(html);
              });
          }
        });
    }

    // Get EXPANDED gray top bar disclosure embeded html
    let disclosure_id_exp = "";

    if (headerEntryId != "" && headerEntryId != undefined) {
      client
        .getEntry(headerEntryId, { locale: currLocale })
        .then((entry: any) => {
          let allFieldsData = entry.fields;
          if (
            allFieldsData.nondisclosureMessage != undefined &&
            allFieldsData.nondisclosureMessage != "" &&
            "target" in
              allFieldsData.nondisclosureMessage[0].fields.expandedText
                .content[1].data
          ) {
            disclosure_id_exp =
              allFieldsData.nondisclosureMessage[0].fields.expandedText
                .content[1].data.target.sys.id;
            //console.log("nondisclosureMessageExp", disclosure_id_exp);
          }
          return disclosure_id_exp;
        })
        .then((entry: any) => {
          if (disclosure_id_exp !== "") {
            client
              .getEntry(disclosure_id_exp, { locale: currLocale })
              .then((entry: any) => {
                return entry.fields.html;
              })
              .then((html: any) => {
                setEmbededHtmlExpanded(html);
              });
          }
        });
    }

    $(".storeAutoCompleteId div input").attr("aria-label", "Find a store");

    if (!document.referrer) {
      if (
        cookies.get("Language") !== undefined ||
        cookies.get("Language") !== ""
      ) {
        let cookieLang = cookies.get("Language");

        if (cookieLang !== defaultLanguage.toLowerCase()) {
          let _pathArray = window.location.pathname.split("/");

          if (_pathArray[1] == "") {
            let path = "/" + cookieLang;
            history.push(path);
          } else if (_pathArray[1] == cookieLang) {
            let path = window.location.pathname + search;
            history.push(path);
          } else if (_pathArray[1] !== cookieLang) {
            let path = "/" + cookieLang + window.location.pathname + search;
            history.push(path);
          }
        }
      }
    }

    const values: any = getHrefLang(props.allAliases);

    if (values !== undefined && values.length > 0) {
      if (values[0] !== undefined && values[0].length > 0) {
        setAllLinksArray(values[0]);
      }
      if (values[1] !== undefined && values[1].length > 0) {
        setStaticLinksArray(values[1]);
      }
    }

    function getGeoInfo() {
      if (countryName === "") {
        axios
          .get("https://ipapi.co/json?key=" + locationApiKey)
          .then((response) => {
            let data = response.data;
            if (data.country_name !== undefined && data.country_name !== "") {
              setCountryName(data.country_name.toLowerCase());
            }
          })
          .catch((error) => {
            console.log("location api error" + error);
          });
      }
    }

    if (
      hostname === "valuevillage.com" ||
      hostname === "www.valuevillage.com" ||
      hostname === "valuevillagecom-react-test.metaltoad-sites.com" ||
      hostname === "www.valuevillagecom-react-test.metaltoad-sites.com" ||
      hostname === "valuevillagecom-react-dev.metaltoad-sites.com" ||
      hostname === "www.valuevillagecom-react-dev.metaltoad-sites.com"
    ) {
      getGeoInfo();
    }

    if (
      countryName == "canada" &&
      (hostname === "valuevillage.com" ||
        hostname === "www.valuevillage.com" ||
        hostname === "www.valuevillagecom-react-test.metaltoad-sites.com" ||
        hostname === "valuevillagecom-react-test.metaltoad-sites.com" ||
        hostname === "valuevillagecom-react-dev.metaltoad-sites.com" ||
        hostname === "www.valuevillagecom-react-dev.metaltoad-sites.com")
    ) {
      if (!document.referrer) {
        if (
          cookies.get("redirect_vvca") == undefined ||
          cookies.get("redirect_vvca") == ""
        ) {
          cookies.set("redirect_vvca", "true", { path: "/", expires: date });
        } else {
          cookies.set("redirect_vvca", "true", { path: "/", expires: date });
        }
      } else {
        let prevHostname = new URL(document.referrer).hostname;
        const charlist = "/";
        prevHostname = prevHostname.replace(
          new RegExp("[" + charlist + "]+$"),
          ""
        );

        if (
          prevHostname === "valuevillage.ca" ||
          prevHostname === "www.valuevillage.ca" ||
          prevHostname === "valuevillageca-react-dev.metaltoad-sites.com" ||
          prevHostname === "www.valuevillageca-react-dev.metaltoad-sites.com" ||
          prevHostname === "valuevillageca-react-test.metaltoad-sites.com" ||
          prevHostname === "www.valuevillageca-react-test.metaltoad-sites.com"
        ) {
          cookies.set("redirect_vvca", "false", { path: "/", expires: date });
        }
      }
    }

    // const currLocale = getLocale();

    if (options.length < 1) {
      client
        .getEntries({
          content_type: "languageToggle",
          "fields.domains": globalData.localeDomain,
          locale: currLocale,
        })
        .then(async (response: any) => {
          if (response.items.length > 0) {
            let optionsArray: any = [];
            response.items.map(async (data: any) => {
              if (data.fields !== undefined || data.fields !== "") {
                if (
                  data.fields.alias !== undefined &&
                  data.fields.alias !== ""
                ) {
                  if (data.fields.flagImage !== undefined) {
                    let redirectLink = "";
                    let toggleLangKey = data.fields.mappingLanguage
                      ? data.fields.mappingLanguage
                      : "";
                    let toggleLangLabel = data.fields.alias
                      ? data.fields.alias
                      : "";
                    let flagIcon = data.fields.flagImage
                      ? data.fields.flagImage.fields.file.url
                      : "";
                    redirectLink = data.fields.redirectUrl
                      ? data.fields.redirectUrl
                      : "";
                    let itemOrder = data.fields.toggleItemOrder
                      ? data.fields.toggleItemOrder
                      : 0;
                    optionsArray.push({
                      langKey: toggleLangKey,
                      langLabel: toggleLangLabel,
                      icon: flagIcon,
                      link: redirectLink,
                      index: itemOrder,
                    });
                    optionsArray.sort((a: any, b: any) =>
                      a.index > b.index ? 1 : -1
                    );
                    await setOptions(optionsArray);
                    setCountryLangToggle(true);
                  } else {
                    optionsArray.push({
                      [data.fields.alias]: data.fields.alias,
                    });
                    optionsArray.sort((a: any, b: any) =>
                      a.index > b.index ? 1 : -1
                    );
                    await setOptions(optionsArray);
                  }
                }
              }
            });
          }
        });
    }

    function setDynamicClass() {
      let val = cookies.get("Language");
      if (val == "en") {
        setDynamicClassName("lang-en");
      } else {
        setDynamicClassName("lang-fr");
      }
    }
    setDynamicClass();

    setPath(window.location.pathname);

    headerEntryId = props.headerEntryId;
    if (headerEntryId != "" && headerEntryId != undefined) {
      client
        .getEntry(headerEntryId, { locale: currLocale })
        .then((entry: any) => {
          if (entry != undefined && entry != "") {
            let allFieldsData = entry.fields;

            if (
              allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca !==
                undefined &&
              allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca !== ""
            ) {
              setAutomaticallyRedirectCaUsersFromVvusToVvca(
                allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca
              );
              if (
                countryName == "canada" &&
                (hostname === "valuevillage.com" ||
                  hostname === "www.valuevillage.com" ||
                  hostname ===
                    "www.valuevillagecom-react-test.metaltoad-sites.com" ||
                  hostname ===
                    "valuevillagecom-react-test.metaltoad-sites.com" ||
                  hostname ===
                    "valuevillagecom-react-dev.metaltoad-sites.com" ||
                  hostname ===
                    "www.valuevillagecom-react-dev.metaltoad-sites.com")
              ) {
                if (
                  allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca ==
                  false
                ) {
                  if (
                    cookies.get("country_popup") == undefined ||
                    cookies.get("country_popup") == ""
                  ) {
                    setShowCountryPopup(true);
                  }
                } else if (
                  allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca ===
                    true &&
                  (cookies.get("redirect_vvca") === "true" ||
                    cookies.get("redirect_vvca") == undefined ||
                    cookies.get("redirect_vvca") == "")
                ) {
                  if (hostname === "valuevillage.com") {
                    window.location.href = "https://www.valuevillage.ca/";
                  } else if (
                    hostname ===
                      "valuevillagecom-react-test.metaltoad-sites.com" ||
                    hostname ===
                      "www.valuevillagecom-react-test.metaltoad-sites.com"
                  ) {
                    window.location.href =
                      "http://valuevillageca-react-test.metaltoad-sites.com";
                  } else if (
                    hostname ===
                      "valuevillagecom-react-dev.metaltoad-sites.com" ||
                    hostname ===
                      "www.valuevillagecom-react-dev.metaltoad-sites.com"
                  ) {
                    window.location.href =
                      "http://valuevillageca-react-dev.metaltoad-sites.com";
                  }
                }
              }
            }

            if (
              allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca ==
              undefined
            ) {
              if (
                countryName == "canada" &&
                (hostname === "valuevillage.com" ||
                  hostname === "www.valuevillage.com")
              ) {
                if (
                  cookies.get("country_popup") == undefined ||
                  cookies.get("country_popup") == ""
                ) {
                  setShowCountryPopup(true);
                }
              }
            }

            if (
              allFieldsData.gtagId !== undefined &&
              allFieldsData.gtagId !== ""
            ) {
              setGtagId(allFieldsData.gtagId);
            }

            if (
              allFieldsData.gtagAdditionalCode !== undefined &&
              allFieldsData.gtagAdditionalCode !== ""
            ) {
              setGtagAdditionalCode(allFieldsData.gtagAdditionalCode);
            }

            if (
              allFieldsData.topHeaderBars != undefined &&
              allFieldsData.topHeaderBars != ""
            ) {
              $.each(
                allFieldsData.topHeaderBars,
                function (i, topHeaderBarData) {
                  if (
                    topHeaderBarData.fields.domains != undefined &&
                    topHeaderBarData.fields.domains != ""
                  ) {
                    if (topHeaderBarData.fields.domains.includes(currDomain)) {
                      setTopHeaderBar(topHeaderBarData.fields.html);
                      // console.log("topHeaderBarData.fields.html", topHeaderBarData.fields.html);
                      return false;
                    }
                  }
                }
              );
            }

            if (
              allFieldsData.siteLogos != undefined &&
              allFieldsData.siteLogos != ""
            ) {
              let fileUrl = "";
              let altText = "";
              $.each(allFieldsData.siteLogos, function (i, siteLogoData) {
                if (siteLogoData.fields.domains !== undefined) {
                  if (siteLogoData.fields.domains.includes(currDomain)) {
                    fileUrl = siteLogoData.fields.image.fields.file.url;
                    altText = siteLogoData.fields.image.fields.title;
                    return false;
                  }
                } else {
                  fileUrl = siteLogoData.fields.image.fields.file.url;
                  altText = siteLogoData.fields.image.fields.title;
                }
              });
              document.getElementsByTagName("meta")[4].content = fileUrl;
              setSiteLogo(fileUrl);
              setSiteLogoAltText(altText);
            }

            if (
              allFieldsData.siteMainMenu != undefined &&
              allFieldsData.siteMainMenu != ""
            ) {
              let allSiteMainMenu: any = [];
              $.each(allFieldsData.siteMainMenu, function (i, value) {
                if (value.fields.domains !== undefined) {
                  if (value.fields.domains.includes(currDomain)) {
                    let singleLink = { title: "", alias: "" };

                    if (
                      value.fields.title != undefined &&
                      value.fields.title != ""
                    ) {
                      singleLink["title"] = value.fields.title;
                    }

                    if (
                      value.fields.alias != undefined &&
                      value.fields.alias != ""
                    ) {
                      singleLink["alias"] = value.fields.alias;
                    }

                    allSiteMainMenu.splice(i, 0, singleLink);
                    setSiteMainMenu(allSiteMainMenu);
                    setSiteMainMenuLength(allSiteMainMenu.length);
                  }
                } else {
                  let singleLink = { title: "", alias: "" };

                  if (
                    value.fields.title != undefined &&
                    value.fields.title != ""
                  ) {
                    singleLink["title"] = value.fields.title;
                  }

                  if (
                    value.fields.alias != undefined &&
                    value.fields.alias != ""
                  ) {
                    singleLink["alias"] = value.fields.alias;
                  }

                  allSiteMainMenu.splice(i, 0, singleLink);
                  setSiteMainMenu(allSiteMainMenu);
                  setSiteMainMenuLength(allSiteMainMenu.length);
                }
              });
            }

            if (
              allFieldsData.topMenu != undefined &&
              allFieldsData.topMenu != ""
            ) {
              let allTopMenu: any = [];
              $.each(allFieldsData.topMenu, function (i, value) {
                if (value.fields.domains !== undefined) {
                  if (value.fields.domains.includes(currDomain)) {
                    let singleLink = { title: "", alias: "", menuItemIcon: "" };

                    if (
                      value.fields.title != undefined &&
                      value.fields.title != ""
                    ) {
                      singleLink["title"] = value.fields.title;
                    }

                    if (
                      value.fields.alias != undefined &&
                      value.fields.alias != ""
                    ) {
                      singleLink["alias"] = value.fields.alias;
                    }

                    if (
                      value.fields.menuItemIcon != undefined &&
                      value.fields.menuItemIcon != ""
                    ) {
                      let fileUrl = value.fields.menuItemIcon.fields.file.url;
                      singleLink["menuItemIcon"] = fileUrl;
                    }

                    allTopMenu.splice(i, 0, singleLink);
                    setTopMenu(allTopMenu);
                    setTopMenuLength(allTopMenu.length);
                  }
                } else {
                  let singleLink = { title: "", alias: "", menuItemIcon: "" };

                  if (
                    value.fields.title != undefined &&
                    value.fields.title != ""
                  ) {
                    singleLink["title"] = value.fields.title;
                  }

                  if (
                    value.fields.alias != undefined &&
                    value.fields.alias != ""
                  ) {
                    singleLink["alias"] = value.fields.alias;
                  }

                  if (
                    value.fields.menuItemIcon != undefined &&
                    value.fields.menuItemIcon != ""
                  ) {
                    let fileUrl = value.fields.menuItemIcon.fields.file.url;
                    singleLink["menuItemIcon"] = fileUrl;
                  }

                  allTopMenu.splice(i, 0, singleLink);
                  setTopMenu(allTopMenu);
                  setTopMenuLength(allTopMenu.length);
                }
              });
            }

            if (
              allFieldsData.thriftProudNavigation != undefined &&
              allFieldsData.thriftProudNavigation != ""
            ) {
              let allThriftProudNavigation: any = [];
              $.each(allFieldsData.thriftProudNavigation, function (i, value) {
                if (value.fields.domains !== undefined) {
                  if (value.fields.domains.includes(currDomain)) {
                    let singleLink = { title: "", alias: "", menuItemIcon: "" };

                    if (
                      value.fields.title != undefined &&
                      value.fields.title != ""
                    ) {
                      singleLink["title"] = value.fields.title;
                    }

                    if (
                      value.fields.alias != undefined &&
                      value.fields.alias != ""
                    ) {
                      singleLink["alias"] = value.fields.alias;
                    }

                    if (
                      value.fields.menuItemIcon != undefined &&
                      value.fields.menuItemIcon != ""
                    ) {
                      let fileUrl = value.fields.menuItemIcon.fields.file.url;
                      singleLink["menuItemIcon"] = fileUrl;
                    }

                    allThriftProudNavigation.splice(i, 0, singleLink);
                    setThriftProudNavigation(allThriftProudNavigation);
                    setThriftProudNavigationLength(
                      allThriftProudNavigation.length
                    );
                  }
                } else {
                  let singleLink = { title: "", alias: "", menuItemIcon: "" };

                  if (
                    value.fields.title != undefined &&
                    value.fields.title != ""
                  ) {
                    singleLink["title"] = value.fields.title;
                  }

                  if (
                    value.fields.alias != undefined &&
                    value.fields.alias != ""
                  ) {
                    singleLink["alias"] = value.fields.alias;
                  }

                  if (
                    value.fields.menuItemIcon != undefined &&
                    value.fields.menuItemIcon != ""
                  ) {
                    let fileUrl = value.fields.menuItemIcon.fields.file.url;
                    singleLink["menuItemIcon"] = fileUrl;
                  }

                  allThriftProudNavigation.splice(i, 0, singleLink);
                  setThriftProudNavigation(allThriftProudNavigation);
                  setThriftProudNavigationLength(
                    allThriftProudNavigation.length
                  );
                }
              });
            }
            if (
              allFieldsData.searchText != undefined &&
              allFieldsData.searchText != ""
            ) {
              setSearchText(allFieldsData.searchText);
              setPlaceholder(allFieldsData.searchText);
            }
            if (
              allFieldsData.searchPlaceholderText != undefined &&
              allFieldsData.searchPlaceholderText != ""
            ) {
              setSearchPlaceholderText(allFieldsData.searchPlaceholderText);
            }

            if (
              allFieldsData.storeText != undefined &&
              allFieldsData.storeText != ""
            ) {
              setStoreText(allFieldsData.storeText);
            }

            if (
              allFieldsData.mobileStoreText != undefined &&
              allFieldsData.mobileStoreText != ""
            ) {
              setMobileStoreText(allFieldsData.mobileStoreText);
            }

            if (
              allFieldsData.additionalCode != undefined &&
              allFieldsData.additionalCode != ""
            ) {
              if (
                hostname === "savers.com" ||
                hostname === "saverscom-react-test.metaltoad-sites.com" ||
                hostname === "saverscom-react-dev.metaltoad-sites.com" ||
                hostname === "www.savers.com" ||
                hostname === "www.saverscom-react-test.metaltoad-sites.com" ||
                hostname === "www.saverscom-react-dev.metaltoad-sites.com" ||
                hostname === "villagedesvaleurs.ca" ||
                hostname ===
                  "villagedesvaleurscom-react-test.metaltoad-sites.com" ||
                hostname ===
                  "villagedesvaleurscom-react-dev.metaltoad-sites.com" ||
                hostname === "www.villagedesvaleurs.ca" ||
                hostname ===
                  "www.villagedesvaleurscom-react-test.metaltoad-sites.com" ||
                hostname ===
                  "www.villagedesvaleurscom-react-dev.metaltoad-sites.com" ||
                hostname === "valuevillage.ca" ||
                hostname === "valuevillageca-react-test.metaltoad-sites.com" ||
                hostname === "valuevillageca-react-dev.metaltoad-sites.com" ||
                hostname === "www.valuevillage.ca" ||
                hostname ===
                  "www.valuevillageca-react-test.metaltoad-sites.com" ||
                hostname === "www.valuevillageca-react-dev.metaltoad-sites.com"
              ) {
                const script = document.createElement("script");
                script.type = "type='application/ld+json";
                script.innerHTML = allFieldsData.additionalCode;
                document.head.appendChild(script);
              }
            }

            const gtmQuery = $(
              'script[src*="https://www.googletagmanager.com/gtm"]'
            );
            if (
              allFieldsData.gtmScript != undefined &&
              allFieldsData.gtmScript != "" &&
              gtmQuery.length === 0
            ) {
              let allGtmScript: any = [];

              if (allFieldsData.gtmScript.length > 0) {
                allFieldsData.gtmScript.map((item: any, i: any) => {
                  let singleGtmScript = {
                    bodyScript: "",
                  };
                  if (item != undefined && item != "") {
                    if (item.fields != undefined && item.fields != "") {
                      if (
                        item.fields.headScript != undefined &&
                        item.fields.headScript
                      ) {
                        const script = document.createElement("script");
                        script.innerHTML = item.fields.headScript;
                        document.head.appendChild(script);
                      }
                      if (
                        item.fields.bodyScript != undefined &&
                        item.fields.bodyScript
                      ) {
                        var noscript: any = document.createElement("noscript");
                        noscript.setAttribute("id", "gtmTags");
                        noscript.innerHTML = item.fields.bodyScript;
                        document.body.appendChild(noscript);
                      }
                    }
                  }
                });
              }
            }
            // console.log('%c allFieldsData.nondisclosureMessage', 'color: #00a8ff; font-weight: bold;', allFieldsData.nondisclosureMessage);
            if (
              allFieldsData.nondisclosureMessage != undefined &&
              allFieldsData.nondisclosureMessage != ""
            ) {
              let minimizedText: any = "";
              let expandedText: any = "";
              $.each(
                allFieldsData.nondisclosureMessage,
                function (i, nondisclosureMessage) {
                  if (nondisclosureMessage.fields.domains !== undefined) {
                    if (
                      nondisclosureMessage.fields.domains.includes(currDomain)
                    ) {
                      if (
                        nondisclosureMessage.fields.minimizedText !==
                          undefined &&
                        nondisclosureMessage.fields.minimizedText !== ""
                      ) {
                        const options = {
                          renderNode: {
                            [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                              if (embededHtml === undefined) {
                                return node.data.target.fields.html;
                              }
                            },
                            [BLOCKS.EMBEDDED_ASSET]: (node: any) =>
                              `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                          },
                        };
                        minimizedText = documentToHtmlString(
                          nondisclosureMessage.fields.minimizedText,
                          options
                        );
                      }
                      if (
                        nondisclosureMessage.fields.expandedText !==
                          undefined &&
                        nondisclosureMessage.fields.expandedText !== ""
                      ) {
                        const options = {
                          renderNode: {
                            [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                              if (embededHtmlExpanded === undefined) {
                                return node.data.target.fields.html;
                              }
                            },
                            [BLOCKS.EMBEDDED_ASSET]: (node: any) =>
                              `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                          },
                        };
                        expandedText = documentToHtmlString(
                          nondisclosureMessage.fields.expandedText,
                          options
                        );
                      }
                      return false;
                    }
                  } else {
                    if (
                      nondisclosureMessage.fields.minimizedText !== undefined &&
                      nondisclosureMessage.fields.minimizedText !== ""
                    ) {
                      const options = {
                        renderNode: {
                          [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                            if (embededHtml === undefined) {
                              return node.data.target.fields.html;
                            }
                          },
                          [BLOCKS.EMBEDDED_ASSET]: (node: any) =>
                            `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                        },
                      };
                      minimizedText = documentToHtmlString(
                        nondisclosureMessage.fields.minimizedText,
                        options
                      );
                    }
                    if (
                      nondisclosureMessage.fields.expandedText !== undefined &&
                      nondisclosureMessage.fields.expandedText !== ""
                    ) {
                      const options = {
                        renderNode: {
                          [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                            if (embededHtmlExpanded === undefined) {
                              return node.data.target.fields.html;
                            }
                          },
                          [BLOCKS.EMBEDDED_ASSET]: (node: any) =>
                            `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                        },
                      };
                      expandedText = documentToHtmlString(
                        nondisclosureMessage.fields.expandedText,
                        options
                      );
                    }
                  }
                }
              );
              setMinimizedText(minimizedText);
              setExpandedText(expandedText);
            }

            if (
              allFieldsData.vvusCanadianUsersTemporaryPopup !== undefined &&
              allFieldsData.vvusCanadianUsersTemporaryPopup !== ""
            ) {
              if (
                allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                  .description !== undefined &&
                allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                  .description !== ""
              ) {
                setTemporaryModalDescription(
                  allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                    .description
                );
              }

              if (
                allFieldsData.vvusCanadianUsersTemporaryPopup.fields.icon !==
                  undefined &&
                allFieldsData.vvusCanadianUsersTemporaryPopup.fields.icon !== ""
              ) {
                let fileUrl =
                  allFieldsData.vvusCanadianUsersTemporaryPopup.fields.icon
                    .fields.file.url;
                setTemporaryModalIcon(fileUrl);
              }

              if (
                allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                  .backgroundColor !== undefined &&
                allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                  .backgroundColor !== ""
              ) {
                setTemporaryModalBg(
                  allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                    .backgroundColor
                );
              }
            }

            if (allFieldsData?.searchMagnifyingGlassColor) {
              setSearchMagnifyingGlassColor(
                allFieldsData.searchMagnifyingGlassColor
              );
            }
            if (allFieldsData?.searchInlineTextColor) {
              setSearchInlineTextColor(allFieldsData.searchInlineTextColor);
            }
            if (allFieldsData?.menuNavigationTextColor) {
              setNavbarLinkColor(allFieldsData.menuNavigationTextColor);
            }
            if (allFieldsData?.menuNavigationUnderlineColor) {
              setNavbarLinkSeparatorColor(
                allFieldsData.menuNavigationUnderlineColor
              );
            }
            if (allFieldsData?.hideSearchPills) {
              setHideSearchPills(allFieldsData.hideSearchPills);
            }
            if (allFieldsData?.desktopNavbarLinkColor) {
              setDesktopNavbarLinkColor(allFieldsData.desktopNavbarLinkColor);
            }
          }
        })
        .catch(console.error);
    }
  }, [props, countryName]);

  const handleChange = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (jQuery(event.target).siblings(".secondary").is(":hidden")) {
      jQuery(event.target).siblings(".secondary").show();
      jQuery(event.target).siblings(".primary").hide();
      jQuery(event.target).addClass("open");
      jQuery(event.target).attr("aria-expanded", "true");
      cookies.set("nondisclosure_message", "expand", {
        path: "/",
        expires: date,
      });
    } else {
      jQuery(event.target).siblings(".secondary").hide();
      jQuery(event.target).siblings(".primary").show();
      jQuery(event.target).removeClass("open");
      jQuery(event.target).attr("aria-expanded", "false");
      cookies.set("nondisclosure_message", "minimize", {
        path: "/",
        expires: date,
      });
    }
  };

  const onFocus = (event: any) => {
    event.target.placeholder = searchPlaceholderText;
  };

  const onBlur = (event: any) => {
    event.target.placeholder = searchText;
  };

  const changeLanguage = (value: any) => {
    setCurrLang(value);
    cookies.set("Language", value, { path: "/", expires: date });

    let cookiePriority = true;
    let selectedLocale = getLocale(cookiePriority);

    let pathArray = location.pathname.split("/");
    const pattern = /[a-z]/;

    if (Object.keys(props.allAliases).length > 0) {
      Object.keys(props.allAliases).map((item: any, i) => {
        if (item == selectedLocale) {
          if (props.allAliases[item] !== "") {
            if (defaultLanguage.toLowerCase() == value) {
              let fullAlias = "/" + props.allAliases[item];
              history.push(fullAlias);
              window.location.reload(true);
            } else {
              let fullAlias = "/" + value + "/" + props.allAliases[item];
              history.push(fullAlias);
              window.location.reload(true);
            }
          } else {
            if (
              pathArray[1] !== undefined &&
              pathArray[1].match(pattern) &&
              pathArray[1].length < 3
            ) {
              pathArray = pathArray.filter(function (el) {
                return el != "";
              });
              pathArray.shift();
              if (defaultLanguage.toLowerCase() == value) {
                let fullAlias = "/" + pathArray.join("/");
                history.push(fullAlias);
                window.location.reload(true);
              } else {
                let fullAlias = "/" + value + "/" + pathArray.join("/");
                history.push(fullAlias);
                window.location.reload(true);
              }
            } else {
              pathArray = pathArray.filter(function (el) {
                return el != "";
              });

              if (defaultLanguage.toLowerCase() == value) {
                let fullAlias = "/" + pathArray.join("/");
                history.push(fullAlias);
                window.location.reload(true);
              } else {
                let fullAlias = "/" + value + "/" + pathArray.join("/");
                history.push(fullAlias);
                window.location.reload(true);
              }
            }
          }
        }
      });
    } else {
      if (
        pathArray[1] !== undefined &&
        pathArray[1].match(pattern) &&
        pathArray[1].length < 3
      ) {
        pathArray = pathArray.filter(function (el) {
          return el != "";
        });
        pathArray.shift();
        if (defaultLanguage.toLowerCase() == value) {
          let fullAlias = "/" + pathArray.join("/");
          history.push(fullAlias);
          window.location.reload(true);
        } else {
          let fullAlias = "/" + value + "/" + pathArray.join("/");
          history.push(fullAlias);
          window.location.reload(true);
        }
      } else {
        pathArray = pathArray.filter(function (el) {
          return el != "";
        });

        if (defaultLanguage.toLowerCase() == value) {
          let fullAlias = "/" + pathArray.join("/");
          history.push(fullAlias);
          window.location.reload(true);
        } else {
          let fullAlias = "/" + value + "/" + pathArray.join("/");
          history.push(fullAlias);
          window.location.reload(true);
        }
      }
    }
  };

  const handleFocus = async (e: any) => {
    if (e.target.value.length > 0) {
      if (storeLocation.length <= 0) {
        await fetch(
          storeUrl + "api/getAutocompleteData?date=" + Date().toLocaleString()
        )
          .then((res) => res.json())
          .then(
            (result) => {
              setStoreLocation((storeLocation) =>
                storeLocation.concat(result.data)
              );
              setDropdownVisible(true);
            },
            (error) => {}
          );
      }
    } else {
      setDropdownVisible(false);
      setStoreLocation([]);
    }
  };

  const selectRedirect = (updatedValue: any) => {
    window.location.href =
      storeUrl +
      "?q=" +
      updatedValue +
      "&site=" +
      storeSite +
      "&lang=" +
      langCookieKey +
      "&country=" +
      countryCode;
  };

  const countryModalClose = (event: any) => {
    const countryPopupDate = new Date();
    countryPopupDate.setFullYear(countryPopupDate.getFullYear() + 10);
    cookies.set("country_popup", "displayed", {
      path: "/",
      expires: countryPopupDate,
    });
    let redirectLink = jQuery(".info a").attr("href");
    if (redirectLink !== "" && redirectLink !== undefined) {
      window.location.href = redirectLink;
    }
    setShowCountryPopup(false);
  };

  if (
    temporaryModalDescription !== "" &&
    temporaryModalDescription !== undefined
  ) {
    jQuery(".info a").click(function () {
      const countryPopupDate = new Date();
      countryPopupDate.setFullYear(countryPopupDate.getFullYear() + 10);
      let redirectLink = jQuery(".info a").attr("href");
      if (redirectLink !== "" && redirectLink !== undefined) {
        cookies.set("country_popup", "displayed", {
          path: "/",
          expires: countryPopupDate,
        });
        window.location.href = redirectLink;
      }
    });
  }

  const changeSite = (value: any) => {
    window.location.href = value;
  };

  const handleTabFocus = () => {
    setDynamicToggleClass("hide-toggle");
    $("#options-view-button").prop("checked", true);
  };

  const handleTabBlur = () => {
    $("#options-view-button").prop("checked", false);
  };

  const handleKeyPressEvent = (e: any, data: any) => {
    if (e.key === "Enter") {
      changeLanguage(data);
    }
  };

  const handleKeyPressEventFlagOptions = (e: any, data: any) => {
    if (e.key === "Enter") {
      changeSite(data);
    }
  };

  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: currLang ? currDomain : getLanguage() }}>
        {allLinksArray.length > 0 &&
          allLinksArray.map((data: any) =>
            options.map((toggleOptionsData: any) =>
              countryLangToggle === false ? (
                Object.keys(toggleOptionsData).map((toggleItems: any) => {
                  if (
                    toggleItems.toLowerCase() === data.lang.toLowerCase() &&
                    countryLangToggle === false
                  ) {
                    if (
                      data.lang.toLowerCase() === defaultLanguage.toLowerCase()
                    ) {
                      return (
                        <link
                          rel="alternate"
                          hrefLang={data.locales.toLowerCase()}
                          href={window.location.origin + "/" + data.urlAlias}
                        />
                      );
                    } else {
                      return (
                        <link
                          rel="alternate"
                          hrefLang={data.locales.toLowerCase()}
                          href={
                            window.location.origin +
                            "/" +
                            data.lang.toLowerCase() +
                            "/" +
                            data.urlAlias
                          }
                        />
                      );
                    }
                  }
                  return null;
                })
              ) : toggleOptionsData.langKey.toLowerCase() ===
                  data.lang.toLowerCase() &&
                window.location.origin.includes(toggleOptionsData.link) ? (
                data.lang.toLowerCase() === defaultLanguage.toLowerCase() ? (
                  <link
                    rel="alternate"
                    hrefLang={data.locales.toLowerCase()}
                    href={window.location.origin + "/" + data.urlAlias}
                  />
                ) : (
                  <link
                    rel="alternate"
                    hrefLang={data.locales.toLowerCase()}
                    href={
                      window.location.origin +
                      "/" +
                      data.lang.toLowerCase() +
                      "/" +
                      data.urlAlias
                    }
                  />
                )
              ) : toggleOptionsData.langKey.toLowerCase() ===
                data.lang.toLowerCase() ? (
                data.externalRedirectUrl !== undefined &&
                data.externalRedirectUrl !== "" ? (
                  <link
                    rel="alternate"
                    hrefLang={data.externalUrlLocale.toLowerCase()}
                    href={data.externalRedirectUrl}
                  />
                ) : (
                  <link
                    rel="alternate"
                    hrefLang={data.externalUrlLocale.toLowerCase()}
                    href={toggleOptionsData.link}
                  />
                )
              ) : null
            )
          )}
        {staticLinksArray.length > 0 &&
          staticLinksArray.map((data: any) => (
            <link rel="alternate" hrefLang={data.locales} href={data.url} />
          ))}
      </Helmet>

      <a href="#maincontent" className="skip" tabIndex={0}>
        Skip to main content
      </a>
      <header
        className={
          topHeaderBar
            ? "main-header v1 " + dynamicClassName
            : "main-header v1 no-top-bar " + dynamicClassName
        }
      >
        {minimizedText || expandedText ? (
          <section className="notify-bar" tabIndex={-1}>
            <div className="inner">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <button
                      onClick={(e) => handleChange(e)}
                      className={
                        nondisclosureMsgCookie == "minimize" ? "" : "open"
                      }
                      aria-expanded={
                        nondisclosureMsgCookie == "minimize" ? "false" : "true"
                      }
                    >
                      <span>notify-bar</span>
                      <span aria-hidden="true">notify-bar</span>
                    </button>
                    {minimizedText ? (
                      <small
                        style={{ ...minimizeOpts }}
                        className="primary"
                        dangerouslySetInnerHTML={{
                          __html: minimizedText + embededHtml,
                        }}
                      ></small>
                    ) : (
                      ""
                    )}
                    {expandedText ? (
                      <small
                        style={{ ...expandOpts }}
                        className="secondary"
                        dangerouslySetInnerHTML={{
                          __html: expandedText + embededHtmlExpanded,
                        }}
                      ></small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        <div className="header-inner">
          {topHeaderBar ? (
            <div
              className="top-bar"
              aria-hidden="true"
              style={{ background: topPromoBarColor }}
            >
              <div className="container">
                <span dangerouslySetInnerHTML={{ __html: topHeaderBar }}></span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="main-menu-listing">
            <div className="container-fluid">
              <div
                className={
                  countryLangToggle
                    ? "langauage-block lang-dropdown-with-flag"
                    : "langauage-block"
                }
                aria-controls="language-menu"
              >
                {countryLangToggle && options ? (
                  options.map((data: any, i: any) => {
                    if (
                      (data.link == undefined || data.link == "") &&
                      getLanguage() == data.langKey
                    ) {
                      return (
                        <Fragment>
                          {data.icon ? (
                            <img
                              className={"flag-icon"}
                              src={data.icon}
                              alt="flag"
                              aria-hidden="true"
                            />
                          ) : (
                            <img
                              className={"globe-icon"}
                              width="13"
                              height="13"
                              src={require("../../scss/images/globe.png")}
                              alt="globe"
                              aria-hidden="true"
                            />
                          )}
                        </Fragment>
                      );
                    }
                  })
                ) : (
                  <img
                    className={"globe-icon"}
                    width="13"
                    height="13"
                    src={require("../../scss/images/globe.png")}
                    alt="globe"
                    aria-hidden="true"
                  />
                )}

                <DropdownMenu trigger={getLanguage().toUpperCase()}>
                  {countryLangToggle && options ? (
                    <DropdownItemGroup>
                      {options.map((data: any, i: any) => {
                        let _lang = data.langLabel.split("/");
                        let countryName = _lang[0].trim();
                        let langName = _lang[1].trim();
                        let countryAriaLabel =
                          countryName === "US"
                            ? "Country is United States and "
                            : "Country is Canada and ";
                        let langAriaLabel =
                          langName === "ES"
                            ? "language is Spanish"
                            : langName === "EN"
                            ? "language is English"
                            : "language is French";
                        return (
                          <DropdownItem
                            onClick={() => {
                              changeLanguage(langName.toLowerCase());
                              changeSite(data.link);
                            }}
                          >
                            <img
                              className={"flag-icon"}
                              src={data.icon}
                              alt="flag"
                              aria-hidden="true"
                            />
                            {data.langLabel}
                          </DropdownItem>
                        );
                      })}
                    </DropdownItemGroup>
                  ) : (
                    // COUNTRY IS ASSUMED BASED ON DOMAIN
                    <DropdownItemGroup>
                      {options.map((data: any) =>
                        Object.keys(data).map((item: any, i) => {
                          return (
                            <DropdownItem
                              onClick={() =>
                                changeLanguage(data[item].toLowerCase())
                              }
                            >
                              {data[item]}
                            </DropdownItem>
                          );
                        })
                      )}
                    </DropdownItemGroup>
                  )}
                </DropdownMenu>
              </div>
              <div
                className="listing-block"
                role="navigation"
                onFocus={handleTabBlur}
              >
                <ul>
                  {topMenu
                    ? topMenu.map((item: any, i: any) => {
                        return (
                          <li>
                            <a
                              href={addLangKeyPath(item.alias)}
                              className={item.alias == path ? "active" : ""}
                              aria-label={item.title}
                            >
                              {item.title}
                            </a>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </div>
          </div>

          <div className="header-inner-main header-inner-main-1">
            <div className="container-fluid">
              <SearchInput
                hide={hideSearchPills}
                searchMagnifyingGlassColor={searchMagnifyingGlassColor}
                searchInlineTextColor={searchInlineTextColor}
                searchPlaceholder={searchPlaceholderText}
                searchText={searchText}
                responsive={false}
              />
              <div className="middle-block">
                <div className="logo-row" tabIndex={-1}>
                  <a href={addLangKeyPath("/")} aria-label="homepage-link">
                    {" "}
                    <img
                      className={"globe-icon"}
                      style={is2ndAve ? { maxHeight: "unset" } : {}}
                      src={siteLogo ? siteLogo : ""}
                      alt={siteLogoAltText ? siteLogoAltText : ""}
                      tabIndex={-1}
                    />
                  </a>
                </div>
                <div className="hero-listing-row" role="navigation">
                  {siteMainMenu?.length > 0 ? (
                    <ul
                      style={{ background: siteMainMenuColor }}
                      css={css`
                        li:after {
                          background: ${desktopNavbarLinkColor} !important;
                        }
                      `}
                    >
                      {siteMainMenu.map((item: any) => {
                        return (
                          <li>
                            <a
                              href={addLangKeyPath(item.alias)}
                              className={item.alias === path ? "active" : ""}
                              style={{ color: desktopNavbarLinkColor }}
                            >
                              {item.title}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </div>
              </div>
              <div className="find-store">
                <FindStore
                  hide={hideSearchPills}
                  searchBorderPillColor={searchMagnifyingGlassColor}
                  searchInlineTextColor={searchInlineTextColor}
                  searchPlaceholder={placeholder}
                  storeUrl={storeUrl}
                  storeSite={storeSite}
                  countryCode={countryCode}
                  langCookieKey={langCookieKey}
                  storeLocation={storeLocation}
                  storeText={storeText}
                  handleFocus={handleFocus}
                  selectRedirect={selectRedirect}
                  dropdownVisible={dropdownVisible}
                  setDropdownVisible={setDropdownVisible}
                  responsive={false}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      <header
        className={
          topHeaderBar
            ? "main-header v2 " + dynamicClassName
            : "main-header v2 no-top-bar " + dynamicClassName
        }
        aria-hidden="true"
      >
        <div className="header-inner">
          {topHeaderBar ? (
            <div
              className="top-bar"
              aria-hidden="true"
              style={{ background: topPromoBarColor }}
            >
              <div className="container">
                {topHeaderBar ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: topHeaderBar }}
                  ></span>
                ) : null}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="header-inner-main header-inner-main-2">
            <div className="container-fluid">
              <div className="logo-row">
                <a
                  href={addLangKeyPath("/")}
                  tabIndex={-1}
                  aria-label="Homepage link"
                >
                  {" "}
                  <img
                    className={"globe-icon"}
                    style={is2ndAve ? { maxHeight: "unset" } : {}}
                    src={siteLogo ? siteLogo : ""}
                    alt={siteLogoAltText ? siteLogoAltText : ""}
                  />
                </a>
              </div>

              <div className="middle-block">
                <div className="hero-listing-row">
                  <ul
                    css={css`
                      li:after {
                        background: ${navbarLinkSeparatorColor} !important;
                      }
                    `}
                  >
                    {siteMainMenu
                      ? siteMainMenu.map((item: any) => {
                          return (
                            <li>
                              <a
                                href={addLangKeyPath(item.alias)}
                                className={item.alias === path ? "active" : ""}
                                tabIndex={-1}
                                style={{ color: navbarLinkColor }}
                              >
                                {item.title}
                              </a>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </div>
              <div className="find-store">
                <div className="dropdown-search">
                  <SearchInput
                    hide={hideSearchPills}
                    searchMagnifyingGlassColor={searchMagnifyingGlassColor}
                    searchInlineTextColor={searchInlineTextColor}
                    searchPlaceholder={searchPlaceholderText}
                    searchText={searchText}
                    responsive={true}
                  />
                </div>
                <div className="dropdown-store">
                  <FindStore
                    hide={hideSearchPills}
                    searchBorderPillColor={searchMagnifyingGlassColor}
                    searchInlineTextColor={searchInlineTextColor}
                    searchPlaceholder={placeholder}
                    storeUrl={storeUrl}
                    storeSite={storeSite}
                    countryCode={countryCode}
                    langCookieKey={langCookieKey}
                    storeLocation={storeLocation}
                    storeText={storeText}
                    handleFocus={handleFocus}
                    selectRedirect={selectRedirect}
                    dropdownVisible={dropdownVisible}
                    setDropdownVisible={setDropdownVisible}
                    responsive={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <header
        className={"mobile-header v1 " + dynamicClassName}
        aria-hidden="true"
      >
        {minimizedText || expandedText ? (
          <section className="notify-bar" aria-hidden="true">
            <div className="inner">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <button
                      onClick={(e) => handleChange(e)}
                      className={
                        nondisclosureMsgCookie == "minimize" ? "" : "open"
                      }
                      aria-expanded={
                        nondisclosureMsgCookie == "minimize" ? "false" : "true"
                      }
                    >
                      <span>notify-bar</span>
                      <span>notify-bar</span>
                    </button>
                    {minimizedText ? (
                      <small
                        style={{ ...minimizeOpts }}
                        className="primary"
                        dangerouslySetInnerHTML={{
                          __html: minimizedText + embededHtml,
                        }}
                      ></small>
                    ) : (
                      ""
                    )}
                    {expandedText ? (
                      <small
                        style={{ ...expandOpts }}
                        className="secondary"
                        dangerouslySetInnerHTML={{
                          __html: expandedText + embededHtmlExpanded,
                        }}
                      ></small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main-top-bar">
                <div
                  className="mobile-toggle"
                  tabIndex={0}
                  aria-expanded="false"
                  aria-label="Hamburger menu"
                  role="button"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

                <div className="center-logo">
                  <a href={addLangKeyPath("/")} aria-label="Homepage link">
                    {" "}
                    <img
                      className={"globe-icon"}
                      style={is2ndAve ? { maxHeight: "unset" } : {}}
                      src={siteLogo ? siteLogo : ""}
                      alt={siteLogoAltText ? siteLogoAltText : ""}
                    />
                  </a>
                </div>

                <div className="right-store-btn">
                  {hideSearchPills ? (
                    <div style={{ width: "86px" }} />
                  ) : (
                    <a href="javascript:void(0);" aria-label="store-link">
                      {mobileStoreText}
                      <i
                        className="fa fa-map-marker"
                        aria-hidden="true"
                        style={{ color: searchMagnifyingGlassColor }}
                      ></i>
                    </a>
                  )}
                </div>
              </div>
              {siteMainMenu?.length > 0 ? (
                <div
                  className="mobile-navbar"
                  style={{ background: siteMainMenuColor }}
                >
                  <ul
                    style={{ background: siteMainMenuColor }}
                    css={css`
                      li:after {
                        background: ${desktopNavbarLinkColor} !important;
                      }
                    `}
                  >
                    {siteMainMenu.map((item: any) => {
                      return (
                        <li>
                          <a
                            href={addLangKeyPath(item.alias)}
                            className={item.alias == path ? "active" : ""}
                            dangerouslySetInnerHTML={{ __html: item.title }}
                            aria-label="menu"
                            style={{ color: desktopNavbarLinkColor }}
                          ></a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              {topHeaderBar ? (
                <div
                  className="mobile-permotion-bar"
                  style={{ background: topPromoBarColor }}
                >
                  <p>
                    {topHeaderBar ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: topHeaderBar }}
                      ></span>
                    ) : null}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="mobile-menu-mask"></div>

        <div className="find-store-collapse-block">
          {storeText ? <h6 className="text-center">{storeText}</h6> : null}
          <img
            className={"close-icon"}
            src={require("../../images/close.png")}
            alt="close"
          />
          <div className="textfield-wrap">
            <form
              action={
                storeUrl +
                "?q=" +
                value +
                "&site=" +
                storeSite +
                "&lang=" +
                langCookieKey +
                "&country=" +
                countryCode
              }
              method="post"
              className="storeAutoCompleteId"
            >
              <Autocomplete
                items={storeLocation}
                inputProps={{ placeholder: storeText }}
                shouldItemRender={(item, value) =>
                  item.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item}
                renderItem={(item, highlighted) => (
                  <div
                    className="items"
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                  >
                    {item}
                  </div>
                )}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  handleFocus(e);
                }}
                onSelect={(value) => {
                  setValue(value);
                  selectRedirect(value);
                }}
                onMenuVisibilityChange={(isOpen) => {
                  value.length > 0
                    ? setDropdownVisible(isOpen)
                    : setDropdownVisible(false);
                }}
                open={dropdownVisible}
              />
              <span
                className="arrow-right"
                aria-hidden="true"
                style={{ background: searchMagnifyingGlassColor }}
              >
                <a
                  href={
                    storeUrl +
                    "?q=" +
                    value +
                    "&site=" +
                    storeSite +
                    "&lang=" +
                    langCookieKey +
                    "&country=" +
                    countryCode
                  }
                  aria-label="Location Search"
                >
                  <i className="fa fa-arrow-right clr-white" />
                </a>
              </span>
            </form>
          </div>
        </div>

        <div className="mobile-menu">
          <div className="menu-close-btn">
            <div
              className="inner"
              tabIndex={0}
              aria-label="Close hamburger menu"
              role="button"
            >
              <span></span>
              <span></span>
            </div>
          </div>
          {!hideSearchPills && (
            <div className="mobile-find-store">
              <button className="btn find-store-btn">
                <i
                  className="fa fa-map-marker"
                  aria-hidden="true"
                  style={{ color: searchMagnifyingGlassColor }}
                ></i>
                <span style={{ color: searchInlineTextColor }}>
                  {storeText ? storeText : ""}
                </span>
              </button>
            </div>
          )}
          <div className="mobile-main-menu">
            <ul>
              {siteMainMenu
                ? siteMainMenu.map((item: any) => {
                    return (
                      <li>
                        <a
                          href={addLangKeyPath(item.alias)}
                          className={item.alias == path ? "active" : ""}
                          dangerouslySetInnerHTML={{ __html: item.title }}
                          aria-label="menu"
                        ></a>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
          <div className="mobile-secondary-menu">
            <ul>
              {topMenu
                ? topMenu.map((item: any) => {
                    return (
                      <li>
                        <a href={addLangKeyPath(item.alias)}>{item.title}</a>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>

          <SearchInput
            hide={hideSearchPills}
            searchMagnifyingGlassColor={searchMagnifyingGlassColor}
            searchInlineTextColor={searchInlineTextColor}
            searchPlaceholder={searchPlaceholderText}
            searchText={searchText}
            responsive={false}
          />
          <div
            className={
              countryLangToggle
                ? "langauage-block lang-dropdown-with-flag"
                : "langauage-block"
            }
            aria-controls="language-menu"
          >
            {countryLangToggle && options ? (
              options.map((data: any, i: any) => {
                if (
                  (data.link == undefined || data.link == "") &&
                  getLanguage() == data.langKey
                ) {
                  return (
                    <Fragment>
                      {data.icon ? (
                        <img
                          className={"flag-icon"}
                          src={data.icon}
                          alt="flag"
                        />
                      ) : (
                        <img
                          className={"globe-icon"}
                          width="13"
                          height="13"
                          src={require("../../scss/images/globe.png")}
                          alt="globe"
                        />
                      )}
                    </Fragment>
                  );
                }
              })
            ) : (
              <img
                className={"globe-icon"}
                width="13"
                height="13"
                src={require("../../scss/images/globe.png")}
                alt="globe"
              />
            )}

            <DropdownMenu trigger={getLanguage().toUpperCase()}>
              {countryLangToggle && options ? (
                <DropdownItemGroup>
                  {options.map((data: any, i: any) => {
                    let _lang = data.langLabel.split("/");
                    let countryName = _lang[0].trim();
                    let langName = _lang[1].trim();
                    let countryAriaLabel =
                      countryName === "US"
                        ? "Country is United States and "
                        : "Country is Canada and ";
                    let langAriaLabel =
                      langName === "ES"
                        ? "language is Spanish"
                        : langName === "EN"
                        ? "language is English"
                        : "language is French";
                    return (
                      <DropdownItem
                        onClick={() => {
                          changeLanguage(langName.toLowerCase());
                          changeSite(data.link);
                        }}
                      >
                        <img
                          className={"flag-icon"}
                          src={data.icon}
                          alt="flag"
                          aria-hidden="true"
                        />
                        {data.langLabel}
                      </DropdownItem>
                    );
                  })}
                </DropdownItemGroup>
              ) : (
                // COUNTRY IS ASSUMED BASED ON DOMAIN
                <DropdownItemGroup>
                  {options.map((data: any) =>
                    Object.keys(data).map((item: any, i) => {
                      return (
                        <DropdownItem
                          onClick={() =>
                            changeLanguage(data[item].toLowerCase())
                          }
                        >
                          {data[item]}
                        </DropdownItem>
                      );
                    })
                  )}
                </DropdownItemGroup>
              )}
            </DropdownMenu>
          </div>
        </div>
        <div className="mobile-store-search">
          <div className="close-btn">
            <span></span>
            <span></span>
          </div>
          {storeText ? <p>{storeText}</p> : null}
          <form
            action={
              storeUrl +
              "?q=" +
              value +
              "&site=" +
              storeSite +
              "&lang=" +
              langCookieKey +
              "&country=" +
              countryCode
            }
            method="post"
            className="storeAutoCompleteId"
          >
            <Autocomplete
              items={storeLocation}
              inputProps={{ placeholder: storeText }}
              shouldItemRender={(item, value) =>
                item.toLowerCase().indexOf(value.toLowerCase()) > -1
              }
              getItemValue={(item) => item}
              renderItem={(item, highlighted) => (
                <div
                  className="items"
                  style={{
                    backgroundColor: highlighted ? "#eee" : "transparent",
                  }}
                >
                  {item}
                </div>
              )}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                handleFocus(e);
              }}
              onSelect={(value) => {
                setValue(value);
                selectRedirect(value);
              }}
              onMenuVisibilityChange={(isOpen) => {
                value.length > 0
                  ? setDropdownVisible(isOpen)
                  : setDropdownVisible(false);
              }}
              open={dropdownVisible}
            />
            <a
              className="arrow-right"
              href={
                storeUrl +
                "?q=" +
                value +
                "&site=" +
                storeSite +
                "&lang=" +
                langCookieKey +
                "&country=" +
                countryCode
              }
            >
              <img
                className="arrow-icon"
                width="20"
                height="20"
                src={require("../../images/arrow-right.png")}
                alt="arrow-right"
              ></img>
            </a>
          </form>
        </div>
      </header>

      <header
        className={
          topHeaderBar
            ? "mobile-header v2 " + dynamicClassName
            : "mobile-header v2 no-top-bar " + dynamicClassName
        }
        aria-hidden="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main-top-bar">
                <div className="mobile-toggle" tabIndex={0}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

                <div className="center-logo">
                  <a href={addLangKeyPath("/")} aria-label="Homepage Link">
                    {" "}
                    <img
                      className={"globe-icon"}
                      style={is2ndAve ? { maxHeight: "unset" } : {}}
                      src={siteLogo ? siteLogo : ""}
                      alt={siteLogoAltText ? siteLogoAltText : ""}
                    />
                  </a>
                </div>

                <div className="right-store-btn">
                  {hideSearchPills ? (
                    <div style={{ width: "86px" }} />
                  ) : (
                    <a href="javascript:void(0);" aria-label="store-link">
                      {mobileStoreText}
                      <i
                        className="fa fa-map-marker"
                        aria-hidden="true"
                        style={{ color: searchMagnifyingGlassColor }}
                      ></i>
                    </a>
                  )}
                </div>
              </div>
              {siteMainMenu?.length > 0 ? (
                <div
                  className="mobile-navbar"
                  style={{ background: siteMainMenuColor }}
                >
                  <ul
                    style={{ background: siteMainMenuColor }}
                    css={css`
                      li:after {
                        background: ${desktopNavbarLinkColor} !important;
                      }
                    `}
                  >
                    {siteMainMenu.map((item: any) => {
                      return (
                        <li>
                          <a
                            href={addLangKeyPath(item.alias)}
                            className={item.alias == path ? "active" : ""}
                            dangerouslySetInnerHTML={{ __html: item.title }}
                            aria-label="Menu"
                            style={{ color: desktopNavbarLinkColor }}
                          ></a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              {topHeaderBar ? (
                <div
                  className="mobile-permotion-bar"
                  style={{ background: topPromoBarColor }}
                >
                  <p>
                    {topHeaderBar ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: topHeaderBar }}
                      ></span>
                    ) : null}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="mobile-menu-mask"></div>

        <div className="find-store-collapse-block">
          {storeText ? <h6 className="text-center">{storeText}</h6> : null}
          <img
            className={"close-icon"}
            src={require("../../images/close.png")}
            alt="close"
          />
          <div className="textfield-wrap">
            <form
              action={
                storeUrl +
                "?q=" +
                value +
                "&site=" +
                storeSite +
                "&lang=" +
                langCookieKey +
                "&country=" +
                countryCode
              }
              method="post"
              className="storeAutoCompleteId"
            >
              <Autocomplete
                items={storeLocation}
                inputProps={{ placeholder: storeText }}
                shouldItemRender={(item, value) =>
                  item.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item}
                renderItem={(item, highlighted) => (
                  <div
                    className="items"
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                  >
                    {item}
                  </div>
                )}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  handleFocus(e);
                }}
                onSelect={(value) => {
                  setValue(value);
                  selectRedirect(value);
                }}
                onMenuVisibilityChange={(isOpen) => {
                  value.length > 0
                    ? setDropdownVisible(isOpen)
                    : setDropdownVisible(false);
                }}
                open={dropdownVisible}
              />
              <span
                className="arrow-right"
                aria-hidden="true"
                style={{ background: searchMagnifyingGlassColor }}
              >
                <a
                  href={
                    storeUrl +
                    "?q=" +
                    value +
                    "&site=" +
                    storeSite +
                    "&lang=" +
                    langCookieKey +
                    "&country=" +
                    countryCode
                  }
                  aria-label="Location Search"
                >
                  <i className="fa fa-arrow-right clr-white" />
                </a>
              </span>
            </form>
          </div>
        </div>

        <div className="mobile-menu">
          <div className="menu-close-btn">
            <div className="inner">
              <span></span>
              <span></span>
            </div>
          </div>
          {!hideSearchPills && (
            <div className="mobile-find-store">
              <button className="btn find-store-btn">
                <i
                  className="fa fa-map-marker"
                  aria-hidden="true"
                  style={{ color: searchMagnifyingGlassColor }}
                ></i>
                {storeText}
              </button>
            </div>
          )}
          <div className="mobile-main-menu">
            <ul>
              {siteMainMenu
                ? siteMainMenu.map((item: any) => {
                    return (
                      <li>
                        <a
                          href={addLangKeyPath(item.alias)}
                          className={item.alias == path ? "active" : ""}
                          dangerouslySetInnerHTML={{ __html: item.title }}
                          aria-label="menu"
                        ></a>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
          <div className="mobile-secondary-menu">
            <ul>
              {topMenu
                ? topMenu.map((item: any) => {
                    return (
                      <li>
                        <a href={addLangKeyPath(item.alias)}>{item.title}</a>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>

          <SearchInput
            hide={hideSearchPills}
            searchMagnifyingGlassColor={searchMagnifyingGlassColor}
            searchInlineTextColor={searchInlineTextColor}
            searchPlaceholder={searchPlaceholderText}
            searchText={placeholder}
            responsive={false}
          />
          <div
            className={
              countryLangToggle
                ? "langauage-block lang-dropdown-with-flag"
                : "langauage-block"
            }
            aria-controls="language-menu"
          >
            {countryLangToggle && options ? (
              options.map((data: any, i: any) => {
                if (
                  (data.link == undefined || data.link == "") &&
                  getLanguage() == data.langKey
                ) {
                  return (
                    <Fragment>
                      {data.icon ? (
                        <img
                          className={"flag-icon"}
                          src={data.icon}
                          alt="flag"
                          aria-hidden="true"
                        />
                      ) : (
                        <img
                          className={"globe-icon"}
                          width="13"
                          height="13"
                          src={require("../../scss/images/globe.png")}
                          alt="globe"
                          aria-hidden="true"
                        />
                      )}
                    </Fragment>
                  );
                }
              })
            ) : (
              <img
                className={"globe-icon"}
                width="13"
                height="13"
                src={require("../../scss/images/globe.png")}
                alt="globe"
                aria-hidden="true"
              />
            )}

            <DropdownMenu trigger={getLanguage().toUpperCase()}>
              {countryLangToggle && options ? (
                <DropdownItemGroup>
                  {options.map((data: any, i: any) => {
                    let _lang = data.langLabel.split("/");
                    let countryName = _lang[0].trim();
                    let langName = _lang[1].trim();
                    let countryAriaLabel =
                      countryName === "US"
                        ? "Country is United States and "
                        : "Country is Canada and ";
                    let langAriaLabel =
                      langName === "ES"
                        ? "language is Spanish"
                        : langName === "EN"
                        ? "language is English"
                        : "language is French";
                    return (
                      <DropdownItem
                        onClick={() => {
                          changeLanguage(langName.toLowerCase());
                          changeSite(data.link);
                        }}
                      >
                        <img
                          className={"flag-icon"}
                          src={data.icon}
                          alt="flag"
                          aria-hidden="true"
                        />
                        {data.langLabel}
                      </DropdownItem>
                    );
                  })}
                </DropdownItemGroup>
              ) : (
                // COUNTRY IS ASSUMED BASED ON DOMAIN
                <DropdownItemGroup>
                  {options.map((data: any) =>
                    Object.keys(data).map((item: any, i) => {
                      return (
                        <DropdownItem
                          onClick={() =>
                            changeLanguage(data[item].toLowerCase())
                          }
                        >
                          {data[item]}
                        </DropdownItem>
                      );
                    })
                  )}
                </DropdownItemGroup>
              )}
            </DropdownMenu>
          </div>
        </div>
        <div className="mobile-store-search">
          <div className="close-btn">
            <span></span>
            <span></span>
          </div>
          {storeText ? <p>{storeText}</p> : null}
          <form
            action={
              storeUrl +
              "?q=" +
              value +
              "&site=" +
              storeSite +
              "&lang=" +
              langCookieKey +
              "&country=" +
              countryCode
            }
            method="post"
            className="storeAutoCompleteId"
          >
            <Autocomplete
              items={storeLocation}
              inputProps={{ placeholder: storeText }}
              shouldItemRender={(item, value) =>
                item.toLowerCase().indexOf(value.toLowerCase()) > -1
              }
              getItemValue={(item) => item}
              renderItem={(item, highlighted) => (
                <div
                  className="items"
                  style={{
                    backgroundColor: highlighted ? "#eee" : "transparent",
                  }}
                >
                  {item}
                </div>
              )}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                handleFocus(e);
              }}
              onSelect={(value) => {
                setValue(value);
                selectRedirect(value);
              }}
              onMenuVisibilityChange={(isOpen) => {
                value.length > 0
                  ? setDropdownVisible(isOpen)
                  : setDropdownVisible(false);
              }}
              open={dropdownVisible}
            />
            <span
              className="arrow-right"
              aria-hidden="true"
              style={{ background: searchMagnifyingGlassColor }}
            >
              <a
                href={
                  storeUrl +
                  "?q=" +
                  value +
                  "&site=" +
                  storeSite +
                  "&lang=" +
                  langCookieKey +
                  "&country=" +
                  countryCode
                }
                aria-label="Location Search"
              >
                <i className="fa fa-arrow-right clr-white" />
              </a>
            </span>
          </form>
        </div>
      </header>
      <Modal
        dialogClassName="country-modal-dialog"
        show={showCountryPopup}
        aria-labelledby="VVUS Canadian users"
        centered
      >
        <Modal.Body className="country-popup-modal">
          <div
            className="modal-body"
            style={{ backgroundColor: temporaryModalBg }}
          >
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(event) => countryModalClose(event)}
            ></button>
            {temporaryModalIcon ? <img src={temporaryModalIcon} /> : ""}
            <div className="clearfix"></div>
            {temporaryModalDescription ? (
              <div
                className="info"
                dangerouslySetInnerHTML={{ __html: temporaryModalDescription }}
              ></div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
  allAliases: state.site.allAliases,
});

Header.defaultProps = {
  fetchSiteData: () => {},
  header: {},
  allAliases: [],
};

export { Header };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
